import * as React from 'react'
import {BannerProps, BannerState} from 'packs/root/Type'
import cn from 'classnames'

export default class BannerPc extends React.Component<BannerProps, BannerState> {
  transitionOrMoveToCenter = (event) => {
    event.preventDefault()

    if (this.props.index === this.props.centerIndex) {
      location.href = this.props.url
    }
  }

  render() {
    return (
      <a href="#" onClick={this.transitionOrMoveToCenter} className="gtag-element" data-event-name='click banner' data-event-category='index page' data-event-label={`${this.props.targetType}_${this.props.targetId}`}>
        <img src={this.props.image} />
      </a>
    )
  }
}
