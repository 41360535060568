import * as React from 'react'
import * as ReactDOM from 'react-dom'
import Carousel from 'packs/root/components/Carousel'
import {Banner} from 'packs/root/Type'

const $carousel: HTMLElement = document.querySelector('.carousel')
const banners: Array<Banner> = JSON.parse($carousel.dataset.banners)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Carousel banners={banners} />,
    $carousel
  )
})
