import * as React from 'react'
import Slider from 'react-slick'
import {CarouselProps, CarouselState} from 'packs/root/Type'
import Banner from 'packs/root/components/Banner'
import Icon from 'components/Icon'

export default class CarouselPc extends React.Component<CarouselProps, CarouselState> {
  constructor(props) {
    super(props)

    this.state = {
      centerIndex: 0
    }
  }

  renderBanner(): Array<JSX.Element> {
    return this.props.banners.map((banner, index) =>
      <Banner url={banner.url} image={banner.image} targetType={banner.targetType} targetId={banner.targetId} key={index} index={index} centerIndex={this.state.centerIndex} />
    )
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 600,
      autoplay: true,
      autoplaySpeed: 3000,
      centerMode: true,
      centerPadding: '0px',
      slidesToShow: 1,
      variableWidth: true,
      focusOnSelect: true,
      afterChange: (index) => this.setState({centerIndex: index})
    }
    return (
      <div className="carousel-inner">
        <Icon name="arrow_left" size="24px" className="slick-prev fill-white" />
        <Icon name="arrow_right" size="24px" className="slick-next fill-white" />
        <Slider {...settings}>
          {this.renderBanner()}
        </Slider>
      </div>
    )
  }
}
